<svg
	xmlns="http://www.w3.org/2000/svg"
	class="translate -rotate-90 flex-shrink-0 h-12 w-12 text-charcoal dark:text-[#D4D4D4]"
	fill="none"
	viewBox="0 0 24 24"
	stroke="currentColor"
>
	<path
		stroke-linecap="round"
		stroke-linejoin="round"
		stroke-width="1"
		d="M12 18h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
	/>
</svg>
