<svg
	role="img"
	viewBox="0 0 24 24"
	class="h-2.5 w-2.5 text-charcoal dark:text-[#D4D4D4]"
	fill="currentColor"
	xmlns="http://www.w3.org/2000/svg"
>
	<title>Windows</title>
	<path
		d="M0 3.449L9.75 2.1v9.451H0m10.949-9.602L24 0v11.4H10.949M0 12.6h9.75v9.451L0 20.699M10.949 12.6H24V24l-12.9-1.801"
	/>
</svg>
