<svg
	class="h-10 h-10 flex-shrink-0 mt-2 text-charcoal dark:text-[#D4D4D4]"
	viewBox="0 0 20 21"
	fill="none"
	xmlns="http://www.w3.org/2000/svg"
>
	<path
		d="M19 13L19 3C19 2.46957 18.7893 1.96086 18.4142 1.58579C18.0391 1.21071 17.5304 0.999999 17 0.999999L3 1C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 0.999999 2.46957 0.999999 3L1 13C1 13.5304 1.21071 14.0391 1.58579 14.4142C1.96086 14.7893 2.46957 15 3 15L17 15C17.5304 15 18.0391 14.7893 18.4142 14.4142C18.7893 14.0391 19 13.5304 19 13Z"
		stroke="currentColor"
		stroke-linecap="round"
		stroke-linejoin="round"
	/>
	<path
		d="M2.20625 17.47C2.38899 17.1776 2.70946 17 3.05425 17H16.9458C17.2905 17 17.611 17.1776 17.7938 17.47L19.0438 19.47C19.46 20.136 18.9812 21 18.1958 21H1.80425C1.01881 21 0.539969 20.136 0.956249 19.47L2.20625 17.47Z"
		fill="currentColor"
	/>
</svg>
