<script>
	import { _ } from 'svelte-i18n';
	import { createEventDispatcher } from 'svelte';
	import SvelteOtp from '@hellocoop/svelte-otp';
	import SpinnerIcon from './icon/SpinnerIcon.svelte';

	const dispatch = createEventDispatcher();

	export let verifyCode;
	export let otp = '';

	export let ajaxRequestSendOTP = false;
	export let ajaxRequestResendOTP = false;

	$: if (otp?.length === 6) {
		verifyCode(otp);
	}
</script>

<form class="text-center">
	<h1 class="text-lg">{$_('Enter verification code')}</h1>
	{#if !ajaxRequestSendOTP}
		<SvelteOtp
			bind:value={otp}
			autofocus={true}
			numOfInputs={6}
			wrapperClass="!gap-x-2 h-20 flex items-center justify-center"
			numberOnly={true}
			inputClass="text-lg !w-9 !h-11 !border-none"
		/>

		<button
			on:click|preventDefault={() => {
				ajaxRequestResendOTP = true;
				dispatch('resend');
			}}
			class="focus:underline hover:underline h-5 text-sm inline-flex items-center justify-center opacity-80 font-medium"
		>
			{#if !ajaxRequestResendOTP}
				{$_('Resend verification code')}
			{:else}
				<SpinnerIcon css="h-5 w-5" />
			{/if}
		</button>
	{:else}
		<div class="relative text-center h-20 flex justify-center items-center">
			<SpinnerIcon css="h-6 w-6" />
		</div>
	{/if}
</form>
