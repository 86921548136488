<script>
	import { createEventDispatcher } from 'svelte';
	import { fly, fade } from 'svelte/transition';

	const dispatch = createEventDispatcher();

	export let dataTest = '';
	export let position = 'center';

	const handleEscKeyClose = (e) => {
		//27 escape key
		if (e.keyCode === 27) {
			dispatch('close');
		}
	};
</script>

<svelte:window on:keydown={handleEscKeyClose} />

<div
	data-test={dataTest}
	id="modal"
	class="pointer {position === 'right'
		? 'right'
		: position === 'left'
		? 'left'
		: 'center'} absolute w-full modal max-w-sm p-3 top-12 rounded-md {$$props.class}"
	style="z-index: 46;"
	transition:fly|local={{ y: 40 }}
>
	<slot />
</div>
<span
	on:click={() => dispatch('close')}
	class="overlay bg-opacity-60"
	style="z-index: 45;"
	aria-hidden="true"
	in:fade|local={{ duration: 300 }}
	out:fade|local={{ duration: 200 }}
/>

<style>
	.pointer::before {
		display: block;
		position: absolute;
		content: '';
		width: 0;
		height: 0;
		border-left: 12px solid transparent;
		border-right: 12px solid transparent;
		top: -12px;
	}

	@media (prefers-color-scheme: dark) {
		.pointer::before {
			border-bottom: 12px solid #808080;
		}
	}

	@media (prefers-color-scheme: light) {
		.pointer::before {
			border-bottom: 12px solid white;
		}
	}

	.center {
		left: 50%;
		transform: translateX(-50%);
	}

	.center::before {
		left: 50%;
		transform: translateX(-50%);
	}

	.right {
		right: 0;
	}

	.right::before {
		right: 20px;
	}
</style>
