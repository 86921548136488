<script>
	import { _, locale } from 'svelte-i18n';
	import SpinnerIcon from '../icon/SpinnerIcon.svelte';
	import { RECOMMENDED_MANAGED_LOGO_SIZE } from '../../constants.js';

	export let loading = false;
	export let disabled = false;
	export let logo = null;
	export let fileName = '';
	export let size = {};
	export let mimeType = '';
</script>

<button
	{disabled}
	class="group h-18 disabled:opacity-50 btn-background disabled:cursor-not-allowed w-full relative overflow-hidden flex items-center justify-start px-4"
	class:cursor-not-allowed={disabled}
	on:click
>
	{#if loading}
		<SpinnerIcon css="h-6 w-6 block mx-auto" />
	{:else}
		{@const isNotRecommendedSize =
			!mimeType.includes('svg') &&
			Math.max(size.width, size.height) < RECOMMENDED_MANAGED_LOGO_SIZE}
		<span class="managed-rounded-square-lg">
			<img src={logo} alt="logo" class="mt-0 w-9 max-h-[36px] object-contain" />
		</span>

		<div
			class="{$locale && $locale.startsWith('ar')
				? 'mr-4 text-right'
				: 'ml-4 text-left'} truncate w-full -mr-3"
		>
			<span class="block">
				{$_('Use this logo')}
			</span>

			<div class="flex justify-between items-center w-full gap-x-2">
				<span class="block truncate">
					{fileName}
				</span>

				<span
					class="block font-light tracking-tight flex-shrink-0"
					class:text-red-500={isNotRecommendedSize}
				>
					{#if mimeType.includes('svg')}
						SVG
					{:else if Number.isInteger(size.width) && Number.isInteger(size.height)}
						{size.width} &times; {size.height}
					{/if}
				</span>
			</div>
		</div>

		<!-- Right arrow icon -->
		<svg
			xmlns="http://www.w3.org/2000/svg"
			class="flex-shrink-0 {$locale && $locale.startsWith('ar')
				? 'mr-auto rotate-90'
				: 'ml-auto -rotate-90'} h-4.5 opacity-80 transform text-white dark:text-[#d4d4d4] group-focus:stroke-3 group-hover:stroke-3 mb-5.5"
			fill="none"
			viewBox="0 0 24 24"
			stroke="currentColor"
			stroke-width="2"
		>
			<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
		</svg>
	{/if}
</button>
